export type OnPageLoad = () => OnNavigate | void;
export type OnNavigate = (e: Event, newBody: HTMLBodyElement) => void;

const cleanups: OnNavigate[] = [];

/**
 * Register a callback to execute when a page loads (via turbolinks, or
 * regurarly).
 *
 * This function is preferred over listening yourself, because this way,
 * different ways of loading a page can be introduced later. If the callback
 * returns a function, it will be executed when there is a navigation event.
 *
 * @param callback
 */
export function onPageLoad(callback: OnPageLoad): void {
  document.addEventListener('turbolinks:load', function load() {
    const cleanup = callback();

    if (cleanup && typeof cleanup === 'function') {
      cleanups.push(cleanup);
    }
  });
}

/**
 * Register a callback to execute when navigating without doing a full document
 * replacement (e.g. via turbolinks).
 *
 * This function is preferred over listening yourself, because this way,
 * different ways of navigating a page can be introduced later.
 *
 * @param callback
 */
export function onNavigate(callback: OnNavigate): void {
  onPageLoad(() => callback);
}

// This is a single event handler to clean up anything that is returned by
// onPageLoad handlers. After running once, these are reset (because subsequent
// cleanup handlers will be registered via the next page load event).
//
document.addEventListener('turbolinks:before-render', function cleanupAll(e) {
  const event = e as Event & { data: { newBody: HTMLBodyElement } };

  cleanups.forEach((cleanup) => cleanup(e, event.data.newBody));
  cleanups.splice(0, cleanups.length);
});
