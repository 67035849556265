export function nextNode(node: Node): ChildNode | null {
  if (node.hasChildNodes()) {
    return node.firstChild;
  }

  while (node && !node.nextSibling) {
    node = node.parentNode as Node;
  }

  if (!node) {
    return null;
  }

  return node.nextSibling;
}

export function getRangeSelectedNodes(range: Range): Node[] {
  let node: Node | null = range.startContainer;
  const endNode = range.endContainer;

  // Special case for a range that is contained within a single node
  if (node == endNode) {
    return [node];
  }

  // Iterate nodes until we hit the end container
  const rangeNodes = [];
  while (node && node != endNode) {
    rangeNodes.push((node = nextNode(node)));
  }

  // Add partially selected nodes at the start of the range
  node = range.startContainer;
  while (node && node != range.commonAncestorContainer) {
    rangeNodes.unshift(node);
    node = node.parentNode;
  }

  return rangeNodes.filter(Boolean) as Node[];
}

export function getSelectedNodes(): Node[] {
  const selection = window.getSelection();
  if (!selection || selection.isCollapsed) {
    return [];
  }

  return getRangeSelectedNodes(selection.getRangeAt(0));
}

/**
 * Asserts if the given node is an HTML Element
 * @param node
 */
export function isHtmlElementNode(
  node: Node | null | undefined
): node is HTMLElement {
  return node?.nodeType === Node.ELEMENT_NODE;
}

/**
 * Asserts if the given node is a text node
 * @param node
 */
export function isTextNode(node: Node | null | undefined): node is Text {
  return node?.nodeType === Node.TEXT_NODE;
}

/**
 * Toggles, sets, or removes any attribute on an element
 *
 * @param attribute the attribute to modify
 * @param element the element to target
 * @param force undefined to toggle, true to set, false to unset
 * @param values [whenTrue, whenFalse], use undefined to remove completely
 */
export function toggleAttribute(
  attribute: string,
  element: HTMLElement | null | undefined,
  force?: boolean,
  values: [string | undefined, string | undefined] | [string] | [] = [
    '',
    undefined,
  ]
): void {
  if (!element) {
    return;
  }

  if (force === undefined) {
    const wasHidden = element.hasAttribute(attribute);
    return toggleAttribute(attribute, element, !wasHidden, values);
  }

  const [whenOn, whenOff] = values;
  const next = force ? whenOn : whenOff;

  if (next === undefined) {
    element.removeAttribute(attribute);
  } else {
    element.setAttribute(attribute, next);
  }
}

/**
 * Toggles, sets, or removes the [hidden] attribute on an element
 *
 * @param element the element to mutate
 * @param force if given, adds the hidden attribute when true, removes otherwise
 */
export function toggleHidden(
  element: HTMLElement | null | undefined,
  force?: boolean
): void {
  toggleAttribute('hidden', element, force);
}

/**
 * Toggles or updates the [aria-expanded] attribute on an element
 *
 * @param element the element to mutate
 * @param force if given, forces the value to match
 */
export function toggleExpanded(
  element: HTMLElement | null | undefined,
  force?: boolean
): void {
  toggleAttribute('aria-expanded', element, force, ['true', 'false']);
}

/**
 * Returns true if the passed string is a printable character
 * @param str
 */
export function isPrintableCharacter(str: string): boolean {
  return !!(str.length === 1 && str.match(/\S/));
}

export function capitalize(input: string): string {
  return input.slice(0, 1).toLocaleUpperCase() + input.slice(1);
}

export function titleize(input: string): string {
  return input.split(' ').map(capitalize).join(' ');
}

export function humanFileSize(number: number): string {
  if (number < 1024) {
    return number + 'bytes';
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + 'KB';
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + 'MB';
  }
  return 'Too large';
}
