import 'yet-another-abortcontroller-polyfill';
import { fetch } from 'whatwg-fetch';
export { default as URLSearchParams } from '@ungap/url-search-params';

// Use native browser implementation if it supports aborting
const abortableFetch = 'signal' in new Request('') ? window.fetch : fetch;

export type ResourceLinks = Record<string, { href: string } | undefined>;
export type Resource<K extends string> = Record<
  K,
  {
    _links?: ResourceLinks;
  }
>;

export type ResourceHtml<K extends string> = Record<
  K,
  {
    content: string;
    _links?: ResourceLinks;
  }
>;

type ErrorResponse = {
  errors: Array<{ message: string }>;
};

type Options = {
  accept: string;
  method?: string;
  signal?: AbortSignal | null;
  headers?: Record<string, string>;
};

/**
 * Parses out the links from a Response.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Link
 *
 * @param response
 * @param base
 */
export function parseLinks(
  response: Response,
  base: ResourceLinks
): ResourceLinks {
  return (response.headers.get('link') || '')
    .split(',')
    .reduce((results, link) => {
      const [linkHref, ...params] = link.split(';');

      // TODO: consider also parsing out the other attributes. Don't need to
      //       do this until they're necessary.
      const rel = params
        .find((param) => param.trim().startsWith('rel='))
        ?.trim()
        ?.slice(5, -1);

      if (rel) {
        results[rel] = { href: linkHref.trim().slice(1, -1) };
      }

      return results;
    }, base);
}

async function fetchResourceUnsafe<
  K extends string,
  R extends Resource<K> = Resource<K>
>(key: K, href: string, options: Options): Promise<R | ResourceHtml<K>> {
  const accept = [
    options.accept,
    'application/vnd.reachora.errors.v1+json; q=0.1',
  ].join(', ');

  const response = await abortableFetch(href, {
    headers: {
      accept,
      ...(options.headers || {}),
    },
    method: options.method || 'get',
    signal: options.signal,
  });

  if (!response.ok) {
    throw response;
  }

  const [contentType] = (response.headers.get('content-type') || '').split(';');

  if (contentType === 'application/json' || contentType.endsWith('+json')) {
    if (!accept.includes(contentType)) {
      throw new Error(
        `Content negotiation failed. Expected ${accept}, actual: ${contentType}.`
      );
    }

    const result = await response.json();
    const obj: Record<string, unknown> = typeof result === 'object' && result;
    if (!obj) {
      throw new Error(`Expected object response. Actual ${typeof result}`);
    }

    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      throw new Error(
        `Expected key '${key}' in result, got ${Object.keys(obj)}`
      );
    }

    const resource = obj as R;
    const _links = resource[key]._links || parseLinks(response, {});

    return {
      ...resource,
      [key]: {
        ...resource[key],
        _links,
      },
    };
  }

  if (contentType === 'text/html' || accept.includes(contentType)) {
    // The following block pulls the links out of the Link header. This is
    // necessary because it can't rely on the links to be present in the
    // body.
    //
    // <https://...>; rel="self", <https://...>; rel="next", ...
    //
    // Result is an object which each link, in the same format as HATEOAS
    // resources return.
    //
    // { self: { href: 'https://...' }, next: { href: 'https://...' } }
    const _links = parseLinks(response, { self: { href } });

    const result = await response.text();

    return {
      [key]: {
        content: result,
        _links,
      },
    } as ResourceHtml<K>;
  }

  throw new Error(`Unsupported media type (${contentType}).`);
}

/**
 *
 *
 * @param url
 * @param accept
 * @param signal
 */
export async function fetchResource<
  K extends string,
  R extends Resource<K> = Resource<K>
>(key: K, href: string, options: Options): Promise<R | ResourceHtml<K>> {
  try {
    return await fetchResourceUnsafe<K, R>(key, href, options);
  } catch (error) {
    if (!(error instanceof Response)) {
      return Promise.reject(error);
    }

    const [contentType] = (error.headers.get('content-type') || '').split(';');

    if (contentType === 'application/vnd.reachora.errors.v1+json') {
      return error
        .json()
        .then(({ errors }: ErrorResponse) =>
          Promise.reject(
            new Error(errors.map((error) => error.message).join(', '))
          )
        );
    }

    // TODO: application/problem+json handling
    return error.text().then((text) => Promise.reject(new Error(text)));
  }
}

// Will either be the native one, or the polyfilled one.
const AbortController = window.AbortController;

export { AbortController, abortableFetch as fetch };
