import onmount from 'onmount';
import { onNavigate, onPageLoad } from './spa';

type Options<E extends Element> = { element: E; id: number };

type Upgrade<E extends Element = Element> = (
  this: E,
  opts: Options<E>
) => void | Downgrade<E>;

type Downgrade<E extends Element = Element> = (
  this: E,
  opts: Options<E>
) => void;

// Tracks mapping between upgraded UJS elements, their upgrade id and their
// downgrade (cleanup) function.
const upgraded: Record<string, Downgrade<Element>> = {};

/**
 * Register behaviour for elements matching the selector.
 *
 * If enter returns a function, it will be used as exit function for any element
 * that matches the selectors. This does *not* override exit, which will (also)
 * run when the element exists.
 *
 * @param selectors
 * @param enter when a matching element is observed
 * @param exit when a matching element is removed
 */
export function register<K extends keyof HTMLElementTagNameMap>(
  selectors: K,
  enter: Upgrade<HTMLElementTagNameMap[K]>,
  exit?: Downgrade<HTMLElementTagNameMap[K]>
): void;
export function register<K extends keyof SVGElementTagNameMap>(
  selectors: K,
  enter: Upgrade<SVGElementTagNameMap[K]>,
  exit?: Downgrade<SVGElementTagNameMap[K]>
): void;
export function register<E extends Element = HTMLElement>(
  selectors: string,
  enter: Upgrade<E>,
  exit?: Downgrade<E>
): void;

export function register<E extends Element = HTMLElement>(
  selectors: string,
  enter: Upgrade<E>,
  exit?: Downgrade<E>
): void {
  onmount<E>(
    selectors,
    function onEnter(opts) {
      try {
        const downgrade = enter.call(this, { ...opts, element: this });

        if (downgrade && typeof downgrade === 'function') {
          upgraded[opts.id] = downgrade as Downgrade<Element>;
        }
      } catch (error) {
        // Log the error, but allow rest of the JavaScript to keep functioning.
        // This allows individual components that register to fail, whilst
        // keeping everything else running.
        //
        // Should prevent most error-on-load script-breaking issues!
        console.error(`[ujs] on enter ${opts.id} failed`, this, error);
      }
    },
    function onExit(opts) {
      if (upgraded[opts.id]) {
        try {
          upgraded[opts.id].call(this, { ...opts, element: this });
        } catch (error) {
          // Log the error, but allow rest of the JavaScript to keep functioning.
          // This allows individual components that register to fail with their
          // cleanup, whilst still cleaning everything else.
          //
          // Should prevent most error-on-navigate via turbolinks
          // script-breaking issues!
          console.error(`[ujs] on exit ${opts.id} failed`, this, error);
        }

        delete upgraded[opts.id];
      }

      // Optional extra exit handler
      if (exit) {
        try {
          exit.call(this, { ...opts, element: this });
        } catch (error) {
          console.error(`[ujs] on exit ${opts.id} failed`, this, error);
        }
      }
    }
  );
}

/**
 * Runs all behaviours for the selectors given.
 */
export function upgrade(selectors: string): void {
  onmount(selectors);
}

/**
 * Runs all behaviour enters
 */
export function upgradeAll(): void {
  onmount();
}

/**
 * Runs all behaviour exits
 */
export function downgradeAll(): void {
  console.debug(`[ujs] teardown ${Object.keys(upgraded).length}`);
  onmount.teardown();
}

onPageLoad(upgradeAll);
onNavigate(downgradeAll);
