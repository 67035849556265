import { register } from '../brandless/ujs';

register('[data-time-skip]', ({ element }) => {
  element.addEventListener('click', (e) => {
    const videoPage = document.getElementById('video-page');
    const video = videoPage.querySelector('video');
    const iframe = videoPage.querySelector('iframe');
    const duration = Number(element.getAttribute('data-time-skip'));

    e.preventDefault();

    if (iframe) {
      // Old player
      iframe.contentWindow.postMessage(
        {
          source: 'reachora_player',
          event_type: 'timeskip',
          duration: duration,
        },
        'https://in.reachora.io'
      );
    } else if (video) {
      // New player
      video.currentTime = duration;
    } else {
      console.debug('Video not available');
    }
  });
});
