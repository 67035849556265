import { AbortController, fetchResource, ResourceLinks } from './fetch';
import { register, upgrade, upgradeAll } from './ujs';
import { toggleAttribute, toggleHidden } from './utils';

type ActionElement = HTMLAnchorElement | HTMLButtonElement;
export type PageResponse = {
  page: {
    content: string;
    _links?: ResourceLinks;
  };
};

class Infinitable {
  private readonly restoreContent: string;
  private readonly abortController: AbortController;
  private observer: IntersectionObserver | undefined;

  constructor(private action: ActionElement) {
    this.restoreContent = this.action.innerHTML;

    this.destroy = this.destroy.bind(this);

    this.onLoadMore = this.onLoadMore.bind(this);
    this.onLoadError = this.onLoadError.bind(this);
    this.onLoaded = this.onLoaded.bind(this);

    this.action.addEventListener('click', this.onLoadMore);
    this.abortController = new AbortController();

    if (this.automatic) {
      this.observe();
    }
  }

  public destroy(): void {
    this.action.removeEventListener('click', this.onLoadMore);
    this.abortController.abort();

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private restore(): void {
    this.action.innerHTML = this.restoreContent;
  }

  private get disabled(): boolean {
    if (this.action instanceof HTMLButtonElement) {
      return this.action.disabled;
    } else if (this.action.classList.contains('btn')) {
      return this.action.classList.contains('btn--disabled');
    } else {
      return this.action.hasAttribute('data-disabled');
    }
  }

  private set disabled(next: boolean) {
    if (this.action instanceof HTMLButtonElement) {
      this.action.disabled = next;
    } else if (this.action.classList.contains('btn')) {
      this.action.classList.toggle('btn--disabled', next);
    } else {
      toggleAttribute('data-disabled', this.action, next);
    }

    if (next) {
      this.action.textContent =
        this.action.getAttribute('data-disable-with') ||
        this.action.textContent;
    } else {
      this.restore();
    }
  }

  private get automatic(): boolean {
    return this.action.hasAttribute('data-auto-infinite');
  }

  private get history(): 'push' | 'replace' | false {
    const value = this.action.getAttribute('data-auto-history');
    if (!value) {
      return this.action.hasAttribute('data-auto-history') ? 'replace' : false;
    }

    return ['push', 'replace'].indexOf(value) === -1
      ? false
      : (value as 'push' | 'replace');
  }

  private get href(): string | null | undefined {
    if (this.action instanceof HTMLAnchorElement) {
      return this.action.href;
    }

    if (this.action.hasAttribute('data-href')) {
      return this.action.getAttribute('data-href') || '';
    }

    return this.action.closest('form')!.action;
  }

  private set href(next: string | null | undefined) {
    if (!next) {
      toggleHidden(this.action, true);
      return;
    }

    if (this.action instanceof HTMLAnchorElement) {
      this.action.href = next;
      return;
    }

    if (this.action.hasAttribute('data-href')) {
      this.action.setAttribute('data-href', next);
      return;
    }

    this.action.closest('form')!.action = next;
  }

  private get accept(): string {
    if (this.action.hasAttribute('data-accept')) {
      return this.action.getAttribute('data-accept') || '';
    }

    // This default is likely not acceptable, and that's fine. The error message
    // in the response, when given, will tell the developer what to use instead.
    return 'application/vnd.reachora.more.v1, application/vnd.reachora.more.v1+json';
  }

  private get method(): string {
    if (this.action.hasAttribute('data-method')) {
      return this.action.getAttribute('data-method') || '';
    }

    if (this.action instanceof HTMLAnchorElement) {
      return 'get';
    }

    return this.action.closest('form')!.method;
  }

  // If this should be available on IE, include the following polyfill.
  // Otherwise will gracefully fallback to button that can be clicked.
  //
  // https://github.com/w3c/IntersectionObserver/tree/master/polyfill
  //
  private observe(): void {
    if (!('IntersectionObserver' in window)) {
      return;
    }

    this.observer = new IntersectionObserver(
      (entries) => {
        // isIntersecting is true when element and viewport are overlapping
        // isIntersecting is false when element and viewport don't overlap
        if (!entries[0].isIntersecting) {
          return;
        }

        if (this.action === entries[0].target) {
          if (this.disabled) {
            return;
          }

          this.loadMore();
          return;
        }

        if (entries[0].target.tagName === 'A') {
          switch (this.history) {
            case 'push': {
              // Can't unpush state.
              return;
            }

            case 'replace': {
              history.replaceState(
                undefined,
                document.title,
                (entries[0].target as HTMLAnchorElement).href
              );
              return;
            }
          }

          return;
        }
      },
      { threshold: [0] }
    );

    this.observer.observe(this.action);
  }

  private onLoadMore(e: Event): void {
    e.preventDefault();

    // Disable data-remote, or anything else
    e.stopPropagation();

    if (this.disabled) {
      return;
    }

    this.loadMore();
  }

  private onLoadError(error: Error): void {
    console.error('[infinite] something went wrong', error);
    this.disabled = false;
  }

  private onLoaded({ page: { content, _links } }: PageResponse): void {
    console.debug(`[infinite] received content ${_links?.self?.href || ''}`);

    // The following is a security risk with user-generated content. It is the
    // server's responsibility to correctly escape any user-generated content.
    const hydrator = document.createElement('div');
    hydrator.innerHTML = content;

    const insertAnchor = this.action.closest('form') || this.action;
    const lastElement = hydrator.lastElementChild;

    // Why start with the last element? It's much more performant to shrink a
    // node's children from the end than to shrink it from the start. But more
    // importantly, this keeps the "action" button on the screen. This tricks
    // browsers to anchor onto this button and not increase the scroll height.
    while (hydrator.lastElementChild) {
      const id = hydrator.lastElementChild.id;

      if (id && document.getElementById(id)) {
        console.warn(`[infinite] received duplicate item (id: ${id})`);
        hydrator.lastElementChild.remove();
        continue;
      }

      insertAnchor.after(hydrator.lastElementChild);
    }

    const currentHref = this.href;

    if (this.history) {
      const back = document.createElement('a');
      back.href = currentHref!;
      back.setAttribute('aria-hidden', 'true');
      back.setAttribute('tabindex', '-1');
      back.style.width = '1px';
      back.style.height = '1px';

      insertAnchor.after(back);
      this.observer?.observe(back);
    }

    // If you read the above and thought: okay, but instead of inserting in
    // reverse, perhaps storing the current scrollHeight, appending and then
    // restorting it might work, like so:
    //
    // window.scrollTo({ top: restoreScrollHeight });
    //
    // ...and it does! However, it will stop the screen from scrolling, which
    // can feel very janky, especially on mobile devices.
    //
    // Instead, move the button back to the bottom of all the elements, so that
    // the "insert after the anchor" method can be used.
    lastElement?.after(insertAnchor);

    // Make all elements just inserted interactive.
    upgradeAll();

    // Update the action button to be able to load more.
    this.disabled = false;
    this.href = _links?.next?.href;

    if (!this.history || !this.href) {
      return;
    }

    switch (this.history) {
      case 'push': {
        window.history.pushState(undefined, document.title, currentHref);
        return;
      }
      case 'replace': {
        window.history.replaceState(undefined, document.title, currentHref);
        return;
      }
    }
  }

  public loadMore(punchThrough: boolean = false): Promise<void> {
    this.disabled = true;

    const href = this.href!;

    return fetchPage(
      href,
      this.method,
      this.accept,
      this.abortController.signal,
      punchThrough
    )
      .then(this.onLoaded)
      .catch(this.onLoadError);
  }
}

export async function fetchPage(
  href: string,
  method: string,
  accept: string,
  signal?: AbortSignal | null,
  punchThrough?: boolean
): Promise<PageResponse> {
  return fetchResource('page', href, {
    method,
    accept,
    signal,
    ...(punchThrough ? { headers: { 'cache-control': 'no-cache' } } : {}),
  });
}

export function infinitables(): void {
  upgrade('[data-action="infinite"]');
}

export function infinitable(component: ActionElement): Infinitable {
  return new Infinitable(component);
}

register<ActionElement>(
  'a[data-action="infinite"], button[data-action="infinite"]',
  ({ element }) => infinitable(element).destroy
);
